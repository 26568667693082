import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import logo from './logo.svg';
import './App.scss';


import Home from "./pages/home/index.js";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
        </Routes>
    </Router>
      
       
    </div>
  );
}

export default App;
