import React, { useContext, useState } from "react";
import Spinner from "../../components/spinner/spinner";
import './index.scss'
import "../../components/nav/Nav.scss";
import logo from "../../assets/images/fox-logo.png";
import { ethers } from "ethers";
import Web3 from "web3";
import SweetAlert2 from 'react-sweetalert2';
import metamask from "../../assets/images/metamask.png";
import walletlogo from "../../assets/images/walletConnect.svg";
import trustlogo from "../../assets/images/trustwallet.svg";
import closeIcon from "../../assets/cancel.png";
import bnblogo from "../../assets/images/bnb.svg";
import WalletConnectProvider from '@walletconnect/web3-provider'

function Index() {

    const [open, setOpen] = useState('swap');
    const [swalProps, setSwalProps] = useState({});
    const [check, setCheck] = useState(false);
    const [connectionType, setConnectionType] = useState('eth');
    const [accept, setAccept] = useState(false);
    const [address, setAddress] = useState('');
    const [balance, setBalance] = useState(0);
    const [newContractAddress, setNewContractAddress] = useState('0x16a7460B9246AE508f18e87bDa4e5B4C1AE8F112');
    const [oldTokenAddress, setOldTokenAddress] = useState('0xFAd8E46123D7b4e77496491769C167FF894d2ACB');
    const [isLoading, setIsLoading] = useState(false);
    const [connectProvider, setConnectProvider] = useState();
    const tokenABI = require("./../../contracts/oldabi.json");
    const migrateABI = require("./../../contracts/newabi.json");
    const weiDivisor = 1000000000;  // TODO: This should be calculated from "contract.decimals()"
    const conversionRate = 1000000;  // TODO: This should be fetched from the contract

    const approveMoreThanBalance = (amount) => {
      return amount + 1; //multiplies times 10, bc this is a string concat
    }

    const switchOpen = () => {
      if(open === 'swap'){
        setOpen('wallet')
      }
      else {
        setOpen('swap')
      }
    }

    const installBSC = () => {
      window.open('https://docs.binance.org/smart-chain/wallet/binance.html', '_blank')
    }

    const installMeta = () => {
      window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank')
    }

    const trustConnect = async () => {
      //  Create WalletConnect Provider
      setConnectionType('connect')
      try{
      const provider = new WalletConnectProvider({
          infuraId: '${process.env.API_KEY}', // Required
          rpc: { 56: 'https://bsc-dataseed.binance.org/' },
          bridge: 'https://bridge.walletconnect.org',
          qrcodeModalOptions: {
            mobileLinks: [
              "rainbow",
              "metamask",
              "argent",
              "trust",
              "imtoken",
              "pillar",
            ],
            desktopLinks: [
              "encrypted ink",
            ]
          }
      });

      //  Enable session (triggers QR Code modal)
      await provider.enable();

      setConnectProvider(provider);
      if (provider.connected) {
        setConnectionType('connect')
        accountChangeHandler(provider.accounts[0]);
          switchOpen()
      }
        provider.on("disconnect", (code, reason) => {
          setAddress('');
          setBalance(0);
        });

        provider.on("accountsChanged", (accounts) => {
          accountChangeHandler(accounts[0]);
        });

    } catch (error) {
      console.log(error, "error");
      setSwalProps({
        show: true,
        title: 'Error',
        text: 'User rejected authorization',
      });
      }
    }

    const bscConnect = async () => {
      try{
      if (window.BinanceChain) {
        // res[0] for fetching a first wallet
        window.BinanceChain
          .request({ method: "eth_requestAccounts" })
          .then((res) => {
            console.log(res, "response");
            setConnectionType('bsc');
            setConnectProvider(window.BinanceChain);
            accountChangeHandler(res[0]);
            switchOpen();
          });
      } else {
        alert("install binance chain wallet extension!!");
      }
    }
    catch (e) {
      alert("Unable to connect to your binance chain wallet!!");
    }
    }

    const handleCheck = () => {
      if(check === false){
      setCheck(true)
      }
      else {
        setCheck(false)
      }
    }
    const acceptHandler = () => {
      setAccept(true)
    }

    const metamaskConnect = () => {
      console.log("Connecting MM");
      if (window.ethereum) {
        // res[0] for fetching a first wallet
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((res) =>
          {
            setConnectionType('eth');
            setConnectProvider(window.ethereum);
            accountChangeHandler(res[0]);
            switchOpen();
          });
      } else {
        alert("install metamask extension!!");
      }
    };

    const getFoxV1Balance = async(address) => {
      //var web3 = new Web3(new Web3.providers.HttpProvider('http://127.0.0.1:8545/'));
      var web3 = new Web3('https://bsc-dataseed1.binance.org:443');
      const tokenInst = new web3.eth.Contract(tokenABI, oldTokenAddress);
      const balance = await tokenInst.methods.balanceOf(address).call();
      console.log("V1 Balance: ", balance);
      setBalance(balance);
      // TODO: React changes the name
      document.getElementById("v1balance").textContent = "Balance: " + balance/weiDivisor;
      document.getElementById("v1amount").value = balance/weiDivisor;
      document.getElementById("v2amount").value = (balance/weiDivisor)/conversionRate;
    };

    // Function for getting handling all events
    const accountChangeHandler = (account) => {
      // Setting an address data
      getFoxV1Balance(account);
      setAddress(account);
    };

    const swapHandler = async(address) => {
      try {
        setIsLoading(true);
        console.log('Swap Provider: ', connectProvider);
        if (connectionType === 'connect'){
          connectProvider.on("disconnect", (code, reason) => {
            setAddress('');
            setBalance(0);
            setIsLoading(false);
            setSwalProps({
              show: true,
              title: 'Error',
              text: 'Wallet Disconnected'
            });
          });
        }
        const web3 = new Web3(connectProvider);
        const contractInstance = new web3.eth.Contract(tokenABI, oldTokenAddress);
        await contractInstance.methods.approve(newContractAddress, approveMoreThanBalance(balance)).send({from: address}, async(error, txHash) => {
          if (error){
            setIsLoading(false);
            setSwalProps({
              show: true,
              title: 'Error',
              text: 'User rejected authorization',
            });
          } else if(txHash) {
            console.log('Approval', txHash);
            const migrateInstance = new web3.eth.Contract(migrateABI, newContractAddress);
            await migrateInstance.methods._migrateFoxTokenToFoxV2().send({from: address}, (error, newTxHash) => {
              if (error){
                console.log('Error at migration');
                setIsLoading(false);
                setSwalProps({
                  show: true,
                  title: 'Error',
                  text: 'User rejected FoxV2 migrate authorization',
                });
              } else if(newTxHash) {
                console.log('Swap', newTxHash);
                setSwalProps({
                   show: true,
                   title: 'Success',
                   text: 'Swap Completed',
                });
                getFoxV1Balance(address);
                if (connectionType === 'eth'){
                  askToImportToken();
                }
              }
            });
          }
        })
      } catch (e) {
        /* // Notification errors will get caught and trigger this note to user if uncommented, which is misleading.
        setSwalProps({
          show: true,
          title: 'Error',
          text: 'Authorization Error',
        });
        */
        console.error(e);
      }
      setIsLoading(false);
    };

    const askToImportToken = async() => {
      const params = {
        type: 'ERC20',
        options: {
          address: newContractAddress,
          symbol: 'FOXV2',
          decimals: 9,
          image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9239.png'
        }
      };
      window.ethereum.request({ method: 'wallet_watchAsset', params }).then((response) => {
        setSwalProps({
          show: true,
          title: 'Success',
          text: 'V2 Token Imported',
        })
      });
    };

    const Disconnect = async() => {
      if (connectionType === 'bsc'){
        await window.BinanceChain.request({
          method: "eth_requestAccounts",
          params: [{eth_accounts: {}}]
        })
        setAddress('');
        setBalance(0);
      }
      else if(connectionType === 'eth'){
        await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [{eth_accounts: {}}]
        })
        setAddress('');
        setBalance(0);
      }
      else if(connectionType === 'connect'){
        const provider = new WalletConnectProvider({
          infuraId: '${process.env.API_KEY}', // Required
          rpc: { 56: 'https://bsc-dataseed.binance.org/' },
          bridge: 'https://bridge.walletconnect.org',
        });

        await provider.disconnect();
        provider.on("disconnect", (code, reason) => {
                console.log(reason)
          setAddress('');
          setBalance(0);

        });
      }
    }

return (
    <div className="home">
       <div className="nav">
          <span className="logo">
            <img  src={logo} className="logo c-pointer" alt="" />
          </span>

        {accept &&
        <div className="wallet">
        <div className="selector">

                {address &&
                <div className='unselected' onClick={Disconnect}>
                   <div className="dot"></div>
                    Disconnect Wallet
                </div>
                 }
                {!address &&
                <div className="selected" onClick={switchOpen}>
                   Connect Wallet
                </div>
                }
                {address &&
                <div className="selected">
                  {address.substring(0,10)}...{address.slice(address.length - 6)}
                </div>
                }
            </div>
            {connectionType === 'bsc' || connectionType === 'eth' || connectionType === 'connect' && address &&
            <div className="settings">
                <div className="icon" onClick={Disconnect}>
                <img  src={closeIcon} height={"30px"} width={"30px"} alt="" />
                    </div>
            </div>
            }
        </div>
        }

          </div>
        {/* <Nav/> */}

        {open === 'swap' &&
        <div className="swap">
          {!accept &&
        <div className="notice">
          <p>To acquire FOX V2 tokens, cold and hot wallet holders will use this swap feature.  Holders must swap all of their V1 tokens and, in exchange, will receive an equal value of FOX V2 tokens.  Note that each FOX V2 token will be worth one million times the value of a single FOX V1 token, so you will receive one millionth the number of FOX tokens when swapping.  For example, if you currently hold 1,000,000,000 FOX V1 tokens, you will receive the equivalent 1,000 FOX V2 tokens without a loss in value.
Please also add the contract address for FOX V2 to your hot or cold wallet so that you can confirm receipt directly in your wallet once the swap is made: (0x16a7460B9246AE508f18e87bDa4e5B4C1AE8F112).
If you hold your FOX V1 tokens on a centralized exchange, no action is required.  The centralized exchange will airdrop the equivalent value of FOX V2 tokens.
If the migration tool does not work for you please send your tokens  to our manual migration wallet. 
This is the following wallet: 0x8D8d0e43c879B6D366b6FE30AB14e3D907e0c492
Every Wednesday the manual migrations are processed. Feel free to reach out in our telegram channel with any further questions.</p>
                  
<div className="ack"><input type="checkbox" defaultChecked={check} onChange={handleCheck}/> Accept</div>
{/* {check && */}
<button className="main-btn" onClick={acceptHandler} disabled={!check}>Accept & Swap</button>
{/* } */}
        </div>
        }
        {accept &&
        <div className="swap-box">

           <div>
            <label>From Fox V1 <span id="v1balance" className="balance">Balance: {balance/weiDivisor} Fox</span></label>
            <input id="v1amount" className="" placeholder={balance/weiDivisor} disabled/>
            <div className="switch">
            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2F4F8"><path d="M22 8.5C22 12.09 19.09 15 15.5 15C15.33 15 15.15 14.99 14.98 14.98C14.73 11.81 12.19 9.26999 9.01999 9.01999C9.00999 8.84999 9 8.67 9 8.5C9 4.91 11.91 2 15.5 2C19.09 2 22 4.91 22 8.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15 15.5C15 19.09 12.09 22 8.5 22C4.91 22 2 19.09 2 15.5C2 11.91 4.91 9 8.5 9C8.67 9 8.84999 9.00999 9.01999 9.01999C12.19 9.26999 14.73 11.81 14.98 14.98C14.99 15.15 15 15.33 15 15.5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.59 2H3C2.45 2 2 2.45 2 3V5.59C2 6.48 3.07999 6.92999 3.70999 6.29999L6.29999 3.70999C6.91999 3.07999 6.48 2 5.59 2Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18.41 22H21C21.55 22 22 21.55 22 21V18.41C22 17.52 20.92 17.07 20.29 17.7L17.7 20.29C17.08 20.92 17.52 22 18.41 22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </div>

        <label>To Fox V2</label>
        <input id="v2amount" className="" placeholder={(balance/weiDivisor)/conversionRate} disabled/>
        {address &&
            <button className="main-btn" onClick={()=>swapHandler(address)}> {isLoading ? <Spinner /> : 'Swap All Fox'}</button>
        }
         {!address &&
            <button className="main-btn" onClick={switchOpen}>Connect Wallet</button>
        }
            </div>



</div>
}
        </div>
        }
        {
          open === 'wallet' &&
          <div className="swap">
            <div className="swap-box">
            <div className="top">
            <label>Connect Wallet</label>
            <div className="settings">

                <div className="icon" onClick={switchOpen}>
                <img  src={closeIcon} height={"30px"} width={"30px"} alt="" />
                    </div>
            </div>
              </div>

              {
                window.ethereum &&
              <div className="list" onClick={metamaskConnect}>
              <img  src={metamask} className="logo" alt="" />
              <div className="title">Metamask</div>

               </div>
               }
               {
                !window.ethereum &&
              <div className="list" onClick={installMeta}>
              <img  src={metamask} className="logo" alt="" />
              <div className="title">Install Metamask</div>

               </div>
               }

                <div className="list" onClick={trustConnect}>
              <img  src={walletlogo} className="logo" alt="" />
              <div className="title">Wallet Connect</div>
                </div>

                <div className="list" onClick={trustConnect}>
              <img  src={trustlogo} className="logo" alt="" />
              <div className="title">Trust Wallet</div>
                </div>

                {
                window.BinanceChain &&
                <div className="list" onClick={bscConnect}>
              <img  src={bnblogo} className="logo" alt="" />
                <div className="title" >Binance Chain Wallet</div>
                </div>
                }
                {
                  !window.BinanceChain &&
                  <div className="list" onClick={installBSC}>
                    <img  src={bnblogo} className="logo" alt="" />
                  <div className="title" >Install Binance Chain Wallet</div>
                  </div>
                }

              </div>

            </div>
        }
        <SweetAlert2 {...swalProps}/>
    </div>
);
}

export default Index;
